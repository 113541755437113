"use client";

import "./index.css";

import dayjs from "dayjs";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

export const MIN_YEAR = 1860;

export const YearSlider = ({
  onChange,
  selectedYear,
  yearLabel,
}: {
  onChange?: (e: any) => void;
  selectedYear: number | null;
  yearLabel?: boolean;
}) => {
  const sliderRef = useRef<HTMLInputElement | null>(null);
  const sliderValueRef = useRef<HTMLInputElement | null>(null);
  const sliderContainerRef = useRef<HTMLDivElement | null>(null);

  const [year, setYear] = useState<number | null>(selectedYear);
  const [input, setInput] = useState<string | null>(null);

  const maxYear = dayjs().year();
  const midPoint = Math.floor((MIN_YEAR + maxYear) / 2);

  const onSlide = (e: ChangeEvent<HTMLInputElement>) => {
    const range = sliderRef.current;
    const output = sliderValueRef.current;
    const container = sliderContainerRef.current;
    if (output && container && range) {
      setYear(range.valueAsNumber);
      setInput(null);
      onChange && onChange(e);
      updateLabelPosition();
    }
  };

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (isNaN(parseInt(e.target.value)) && e.target.value !== '') {
      return;
    }

    setInput(e.target.value);
    if (e.target.value.length < 4) return;

    const n = Number(e.target.value);
    const inRange =
      n >= Number(MIN_YEAR) && n <= Number(maxYear);

    if (inRange) {
      setYear(n);
      setInput(null);
      onChange && onChange(e);
      updateLabelPosition();
      e.target.blur();
    } else {
      toast(
        "You must enter a year between " + MIN_YEAR + " and " + maxYear,
        {
          type: "error",
        },
      );
    }
  };

  // select the input when focused
  const onFocusInput = () => {
    sliderValueRef.current?.select();
  }

  // if user unfocuses, validate and show feedback
  const onBlurInput = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 4) return;

    toast(
      "You must enter a year between " + MIN_YEAR + " and " + maxYear,
      {
        type: "error",
      },
    );
  }

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sliderValueRef.current?.blur();
    }
  };

  const updateLabelPosition = () => {
    const range = sliderRef.current;
    const output = sliderValueRef.current;
    const container = sliderContainerRef.current;

    if (output && range && container) {
      container.style.left =
        getLabelPosition(
          Number(range.max),
          Number(range.min),
          range.valueAsNumber,
        ) + "px";
      container.style.transform = "translateX(-50%) scale(1)";
      container.style.opacity = String(1);
    }
  };

  const getLabelPosition = (a: number, b: number, c: number) => {
    const length = Number(a) - Number(b);
    const range = sliderRef.current;
    if (range) {
      const off = (range.clientWidth - 24) / length;
      const px = (c - b) * off - (range.clientWidth - 24) / 2;
      return Number(range.clientWidth / 2) + Number(px);
    }
    return 0;
  };

  useEffect(() => {
    updateLabelPosition();
  }, [year]);

  useEffect(() => {
    window.addEventListener("resize", updateLabelPosition, false);
    updateLabelPosition();
    return () => {
      window.removeEventListener("resize", updateLabelPosition, false);
    };
  }, []);

  return (
    <>
      {yearLabel && <h2 className="pb-2 text-xl font-medium uppercase">Year</h2>}
      <div className="relative w-full">
        <div className="sliderDivider" />
        <div className="relative">
          <input
            type="range"
            min={MIN_YEAR}
            max={maxYear}
            className={`slider ${!year ? 'noYearChosen' : ''}`}
            onInput={onSlide}
            ref={sliderRef}
            aria-label={"Year Selector"}
            value={year ?? midPoint} // to place slider in the middle even when year has not been selected yet
          />
          <div className="selector group" ref={sliderContainerRef}>
            <output
              className={`selectValue pointer-events-auto relative flex
              h-10 items-center justify-center lg:h-10 ${!year && !input ? 'noYearChosen' : ''}`}
            >
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                {!year && !input && <div className="absolute -right-0.5 -top-0.5 z-10 size-2 rounded-full bg-[#DF2020]"/>}
                <input
                  type="text"
                  ref={sliderValueRef}
                  inputMode="numeric"
                  maxLength={4}
                  className="flex size-full cursor-text items-center justify-center rounded-lg border border-base-300 bg-transparent text-center transition-all duration-300 ease-linear focus:outline-2 focus:outline-neutral"
                  onChange={onInput}
                  onFocus={onFocusInput}
                  onBlur={onBlurInput}
                  onKeyDown={onKeyDownHandler}
                  value={input ?? year ?? '....'}
                />
              </div>
            </output>
          </div>
        </div>

          <div className="flex w-full flex-row justify-between py-0.5 text-sm text-base-content/80">
            <span>{MIN_YEAR}</span>
            <span>{maxYear}</span>
          </div>
      </div>
    </>
  );
};
