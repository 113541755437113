import 'swiper/css';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import CommunityIcon from '@mui/icons-material/PhotoFilter';
import { getCommunityGames } from '@wt/utilities/database';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getPuzzle } from '../game/_utils/getPuzzle';

export default function GameButtons() {
  const [archiveImageUrl, setArchiveImageUrl] = useState<string>('');
  const [dailyGameImageUrl, setDailyGameImageUrl] = useState<string>('');
  const [communityImageUrl, setCommunityImageUrl] = useState<string>('');

  useEffect(() => {
    const getImages = async () => {
      try {
        const today = dayjs();
        const yesterday = today.subtract(1, 'day');

        const getDailyImage = async () => {
          try {
            getPuzzle(today.format('YYYY-MM-DD'), 'daily').then((puzzle) =>
              setDailyGameImageUrl(puzzle[0].url)
            );
          } catch (e) {
            return;
          }
        };

        const getArchiveImage = async () => {
          try {
            getPuzzle(yesterday.format('YYYY-MM-DD'), 'archive').then(
              (puzzle) => setArchiveImageUrl(puzzle[0].url)
            );
          } catch (e) {
            return;
          }
        };

        const getCommunityImage = async () => {
          try {
            const { games } = await getCommunityGames({
              sortBy: 'newest',
              offset: 0,
              limit: 1,
            });
            getPuzzle(games[0].slug, 'community').then((puzzle) =>
              setCommunityImageUrl(puzzle[0].url)
            );
          } catch (e) {
            return;
          }
        };

        await Promise.all([
          getDailyImage(),
          getArchiveImage(),
          getCommunityImage(),
        ]);
      } catch (e) {
        return;
      }
    };

    getImages();
  }, []);

  return (
    <>
      <GameButtonsDesktop
        archiveImageUrl={archiveImageUrl}
        dailyGameImageUrl={dailyGameImageUrl}
        communityImageUrl={communityImageUrl}
      />
      <GameButtonsMobile
        archiveImageUrl={archiveImageUrl}
        dailyGameImageUrl={dailyGameImageUrl}
        communityImageUrl={communityImageUrl}
      />
    </>
  );
}

const GameButtonsDesktop = ({
  archiveImageUrl,
  dailyGameImageUrl,
  communityImageUrl,
}: {
  archiveImageUrl: string;
  dailyGameImageUrl: string;
  communityImageUrl: string;
}) => {
  return (
    <div className="hidden w-full flex-row justify-center gap-6 px-8 md:flex">
      <ArchiveButton imageUrl={archiveImageUrl} />
      <DailyGameButton imageUrl={dailyGameImageUrl} />
      <CommunityButton imageUrl={communityImageUrl} />
    </div>
  );
};

const GameButtonsMobile = ({
  archiveImageUrl,
  dailyGameImageUrl,
  communityImageUrl,
}: {
  archiveImageUrl: string;
  dailyGameImageUrl: string;
  communityImageUrl: string;
}) => {
  return (
    <div className="flex w-full md:hidden">
      <Swiper
        centeredSlides={true}
        loop={false}
        initialSlide={1}
        breakpoints={{
          300: {
            slidesPerView: 1.6,
            spaceBetween: 24,
          },
          350: {
            slidesPerView: 1.8,
            spaceBetween: 24,
          },
          400: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          450: {
            slidesPerView: 2.2,
            spaceBetween: 24,
          },
          500: {
            slidesPerView: 2.4,
            spaceBetween: 24,
          },
          550: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        }}
      >
        <SwiperSlide>
          <ArchiveButton imageUrl={archiveImageUrl} />
        </SwiperSlide>
        <SwiperSlide>
          <DailyGameButton imageUrl={dailyGameImageUrl} />
        </SwiperSlide>
        <SwiperSlide>
          <CommunityButton imageUrl={communityImageUrl} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

const ArchiveButton = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <GameButton
      icon={<ArchiveOutlinedIcon fontSize="inherit" />}
      label="Archive"
      href="/archive"
      imageUrl={imageUrl}
    />
  );
};

const DailyGameButton = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <GameButton
      icon={<LoopRoundedIcon fontSize="inherit" />}
      label="Daily Game"
      href="/game"
      imageUrl={imageUrl}
    />
  );
};

const CommunityButton = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <GameButton
      icon={<CommunityIcon fontSize="inherit" />}
      label="Community"
      href="/community"
      imageUrl={imageUrl}
    />
  );
};

const GameButton = ({
  icon,
  label,
  href,
  imageUrl,
}: {
  icon: JSX.Element;
  label: string;
  href: string;
  imageUrl: string;
}) => {
  return (
    <Link
      href={href}
      className="relative flex size-[200px] rounded-3xl bg-cover bg-center drop-shadow-lg transition-transform duration-300 ease-in-out md:h-[240px] md:w-[380px] md:hover:scale-110"
      style={{ backgroundImage: `url("${imageUrl}")` }}
    >
      <div className="absolute inset-0 flex items-center justify-center rounded-3xl bg-black/50">
        <div className="flex flex-row items-center justify-center gap-3 text-xl font-medium text-primary-content md:text-2xl lg:text-4xl">
          <span className="text-pale-highlight">{icon}</span>
          <span>{label}</span>
        </div>
      </div>
    </Link>
  );
};
