import { MapSelectorTutorial } from '@wt/game/components/elements/MapSelector';
import fonts from '@wt/shared/assets/fonts';
import { getSiteMode } from '@wt/utilities/siteMode';
import { useEffect, useRef, useState } from 'react';

interface Clue {
  top: string;
  left: string;
  clue: string;
}

const clues = [
  { top: '60%', left: '76%', clue: 'fashion from the 80-s' },
  { top: '52%', left: '48%', clue: 'red double-decker = London' },
  { top: '73%', left: '15%', clue: 'driving on the left side' },
  { top: '63%', left: '5%', clue: 'car looks more like 90-s' },
  { top: '20%', left: '18%', clue: 'old-style signs, probably the 70-s' },
  { top: '85%', left: '85%', clue: 'shot on film, not digital' },
  { top: '24%', left: '82%', clue: 'English-speaking country' },
  { top: '32%', left: '42%', clue: 'old buildings - city centre' },
];

export const ExampleImage = ({ showAnswer }: { showAnswer: boolean }) => {
  const [activeClueIndex, setActiveClueIndex] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const resetInterval = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setActiveClueIndex((prevIndex) => (prevIndex + 1) % clues.length);
    }, 3000);
  };

  useEffect(() => {
    resetInterval();
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const handleClick = (index: number) => {
    if (window.innerWidth < 768) {
      setActiveClueIndex(index);
      if (intervalRef.current) clearInterval(intervalRef.current);
      resetInterval();
    }
  };

  const handleHover = (index: number) => {
    if (window.innerWidth > 768) {
      setActiveClueIndex(index);
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  };

  const handleLeave = () => {
    resetInterval();
  };

  return (
    <div className="flex w-full flex-col">
      <div className="relative flex h-12 w-full items-center justify-center md:hidden">
        {!showAnswer &&
          clues.map((clue, i) => (
            <MobileClueTag
              key={i}
              clue={clue}
              isActive={activeClueIndex === i}
            />
          ))}
      </div>
      <div
        className="relative flex aspect-[1.5] w-full overflow-hidden rounded-3xl bg-cover bg-center md:overflow-visible"
        style={{ backgroundImage: 'url("/assets/images/london.jpg")' }}
      >
        {!showAnswer &&
          clues.map((clue, i) => (
            <span
              key={i}
              className={`absolute z-10 flex size-4 items-center justify-center rounded-full border border-primary bg-primary-content drop-shadow-lg transition-transform duration-300 ease-in-out ${i === activeClueIndex ? 'scale-150' : 'scale-100'} hover:cursor-pointer`}
              style={{ top: clue.top, left: clue.left }}
              onClick={() => handleClick(i)}
              onMouseEnter={() => handleHover(i)}
              onMouseLeave={handleLeave}
            >
              <span className="block size-[8px] rounded-full bg-primary" />
            </span>
          ))}
        <div className="hidden md:flex">
          {!showAnswer &&
            clues.map((clue, i) => (
              <DesktopClueTag
                key={i}
                clue={clue}
                isActive={activeClueIndex === i}
              />
            ))}
        </div>

        {showAnswer && (
          <>
            <MapAnswer />
            <YearAndDescriptionMobile />
            <YearAndDescriptionDesktop />
          </>
        )}
      </div>
    </div>
  );
};

const MobileClueTag = ({
  clue,
  isActive,
}: {
  clue: Clue;
  isActive: boolean;
}) => {
  return (
    <div
      className={`absolute flex rounded-2xl bg-primary-content px-3 py-1 text-center text-sm text-primary drop-shadow-lg transition-opacity duration-500 ease-in-out ${isActive ? 'opacity-100' : 'opacity-0'}`}
      style={fonts.gaegu.style}
    >
      {clue.clue}
    </div>
  );
};

const DesktopClueTag = ({
  clue,
  isActive,
}: {
  clue: Clue;
  isActive: boolean;
}) => {
  const tagRef = useRef<HTMLDivElement>(null);
  const [tagWidth, setTagWidth] = useState(0);

  useEffect(() => {
    if (tagRef.current) {
      setTagWidth(tagRef.current.offsetWidth);
    }
  }, [clue, tagRef.current]);

  return (
    <div
      ref={tagRef}
      className={`absolute flex text-nowrap rounded-2xl bg-primary-content px-3 py-1 text-center text-sm text-primary drop-shadow-lg transition-opacity duration-500 ease-in-out ${isActive ? 'z-[11] opacity-100' : 'opacity-0'}`}
      style={{
        ...fonts.gaegu.style,
        top: `calc(${clue.top} - 10%)`,
        left: `calc(${clue.left} - ${tagWidth / 2}px)`,
      }}
    >
      {clue.clue}
    </div>
  );
};

const MapAnswer = () => {
  return (
    <div className="absolute bottom-4 right-4 z-[11] flex size-16 items-center justify-center overflow-hidden rounded-xl border border-black shadow-lg md:size-36">
      <MapSelectorTutorial latitude={51.5142} longitude={-0.1504} label={''} />
    </div>
  );
};

const year = 1989;
const description = <>London, west on Oxford Street at James Street.</>;
const descriptionWheretaken = (
  <>
    <strong>London</strong>, west on Oxford Street at James Street.
  </>
);

const YearAndDescriptionMobile = () => {
  if (getSiteMode() == 'whentaken')
    return (
      <div className="absolute bottom-0 z-10 flex min-h-24 w-full shrink flex-col rounded-b-md bg-black/40 p-2 pr-24 text-white md:hidden">
        <div className="text-xl font-semibold">{year}</div>
        <div className="mt-1 flex w-full grow basis-0 flex-col items-start justify-center gap-1.5 border-t border-primary-content">
          <div className="mt-1 basis-0 text-sm leading-tight">
            {description}
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="absolute bottom-0 z-10 flex min-h-24 w-full shrink flex-col justify-center rounded-b-md bg-black/40 p-2 pr-24 text-white md:hidden">
        <div className="mt-1 basis-0 text-md leading-tight">
          {descriptionWheretaken}
        </div>
      </div>
    );
};

const YearAndDescriptionDesktop = () => {
  if (getSiteMode() == 'whentaken')
    return (
      <div className="absolute bottom-4 right-40 mx-4 hidden flex-row items-center gap-3 rounded-lg bg-black/40 p-4 text-white md:flex">
        <div className="text-xl font-bold lg:text-3xl">{year}</div>
        <div className="border-l-4 border-base-200 pl-3 text-base">
          {description}
        </div>
      </div>
    );
  else
    return (
      <div className="absolute bottom-4 right-40 mx-4 hidden flex-row items-center gap-3 rounded-lg bg-black/40 p-4 text-white md:flex">
        <div className="border-l-4 border-base-200 pl-3 text-base">
          {descriptionWheretaken}
        </div>
      </div>
    );
};
